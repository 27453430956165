html {
  height: 100%;
  width: 100%;
}

body {
  width: 100%;
  margin: 0;

  /*scroll-bouncing: https://www.bram.us/2016/05/02/prevent-overscroll-bounce-in-ios-mobilesafari-pure-css/ */

  /* safari scroll-bouncing */
  overscroll-behavior: none;
}

html, body {
  overscroll-behavior-y: contain;
}

#root {
  width: 100%;
  height: 100%;
}
